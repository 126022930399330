

const init ={
    isLoading : false,
    isError : false,
    data :[]
}

const appReducer =(state=init,{type,payload})=>{

    switch(type){




        
        default : {
            return state
        }
    }

}

export {appReducer}